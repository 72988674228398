<template>
  <b-container class="mt-3 px-1 px-lg-2">
    <div class="container mb-3 mt-0 content-box">
      <div class="row p-2" :class="{'message-box-container' : messages.length === 0}">
        <div class="col-lg-4 col-12 p-0" v-bind:class="{'hidden-sm' : !smallScreenView}">
          <div class="mr-0 mr-lg-3 message-list-column h-100">
            <div class="list-group-item mb-0 border-0" style="cursor: default">
              <h4 class="font-weight-bold m-0">{{ $t('inbox') }}</h4>
              <span class="small">
                <span class="font-weight-bold">{{ newMessages }}</span> {{ $tc('inbox_new_message', newMessages) }}
              </span>
              <div class="mt-2">
                <div class="btn-group btn-group-sm" role="group">
                  <button type="button" class="btn" :class="[sortBy === 'default' ? 'btn-primary': 'btn-outline-primary']" @click="updateSort('default')">{{ $t('inbox_sort.default') }}</button>
                  <button type="button" class="btn" :class="[sortBy === 'newest' ? 'btn-primary': 'btn-outline-primary']" @click="updateSort('newest')">{{ $t('inbox_sort.newest') }}</button>
                  <button type="button" class="btn" :class="[sortBy === 'unread' ? 'btn-primary': 'btn-outline-primary']" @click="updateSort('unread')">{{ $t('inbox_sort.unread') }}</button>
                </div>
              </div>
            </div>
            <div v-if="!inboxLoaded " class="mx-auto my-5 d-lg-none d-xl-none">
              <b-spinner large label="Large Spinner"/>
              <div>{{ $t('loading_inbox') }}</div>
            </div>
            <div v-if="inboxLoaded && messageLoaded && messages.length === 0 && inbox.length === 0" class="mx-auto no-messages pt-5 d-lg-none d-xl-none">
              <h3>{{ $t('no_messages') }}</h3>
              <i class="fa-4x mb-3 fa-regular fa-comments"/>
            </div>
            <div class="message-list" v-if="inboxLoaded">
              <div id="home" class="message-chat-history-list" @scroll="onScroll">
                <div class="list-group border-0 " v-for="(message, index) in inbox" :key="index" :id="'message-chat-inbox-' + message.hash">
                  <div v-lazy-container="{ selector: 'img' }" class="list-group-item py-3 px-2 pr-3 border-0 message-container d-flex h-100" :id="'messageSeen' + index"
                       @click="lastActive.to = message.to_last_active; lastActive.from = message.from_last_active; getMessage(message.hash, 'messageSeen' + index, message.locked, true, message.from_username, message.to_username )"
                       :class="{'message-active': activeInbox === 'messageSeen' + index, 'not-seen' : (message.unread > 0) }">
                    <inbox-item :message="message" :current-user="myUsername" :withinLastSeconds="withinLastSeconds" :toLastActive="message.to_last_active"
                                :fromLastActive="message.from_last_active" :ref="'inboxItem' + index"/>
                  </div>
                </div>
                <div v-if="pagination.per_page < pagination.total" class="w-100 my-4">
                  <div class="d-inline-flex justify-content-center load-more p-2">
                    <b-spinner class="mr-2" small label="Small Spinner"/>
                    <span>{{ $t('loading_more') }} ({{ pagination.total }}&nbsp;{{ $t('total') }})</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="message-container" class="col-sm-12 col-md-12 col-lg-8 px-0" v-bind:class="{'hidden-sm' : smallScreenView}">
          <!-- chat -->
          <div v-if="messages.length > 0" class="h-100">
            <transition name="fade">
              <MessageInbox :toLastActiveDate="lastActive.to" :fromLastActiveDate="lastActive.from" :withinLastSeconds="withinLastSeconds" :messages="messages" device="device"
                            :unreadMessage="unreadMessages" :username="username" :title="taskTitle" v-if="messageLoaded" :displayInbox="displayInbox" :hash="activeHash"
                            :activeMessage="activeMessage" :lock="activeLock" :customDetail="customDetail" :fromUsername="fromUsername" :toUsername="toUsername"
                            :jobDetails="jobDetail" @job-button-open-offer="handleJobOpenOffer" @job-button-cancel-offer="handleJobCancel"
                            ref="messageInbox"
              />
            </transition>
            <div v-if="!messageLoaded" class="d-flex justify-content-center align-items-center h-100">
              <div class="d-flex flex-column align-items-center">
                <b-spinner large label="Large Spinner" class="mb-2"/>
                <div>{{ $t('message_loading') }}</div>
              </div>
            </div>
          </div>
          <div v-if="!inboxLoaded" class="d-flex justify-content-center align-items-center h-100">
            <div class="d-flex flex-column align-items-center py-5">
              <b-spinner large label="Large Spinner"/>
              <div>{{ $t('message_loading') }}</div>
            </div>
          </div>
          <div v-if="inboxLoaded && messageLoaded && messages.length === 0" class="row h-100">
            <div class="mx-auto no-messages flex-col justify-content-center align-items-center">
              <h3>{{ $t('no_messages') }}</h3>
              <i class="mb-3 fa-regular fa-comments fa-4x"/>
            </div>
          </div>
        </div>

      </div>
    </div>

    <job-offer
      v-if="jobDetail !== null"
      :job="jobDetail"
    />
  </b-container>
</template>

<script>
import ApiService from '../../apiService.js'
import MessageInbox from '../../components/MyAccount/Inbox/MessageInbox'
import helper from '../../helper'
import { mapGetters, mapState, mapActions } from 'vuex'
import InboxItem from '../../components/Message/InboxItem'
import threadsCreatedSubscription from '../../graphql/threadsCreatedSubscription'
import threadMessagesCreatedSubscription from '../../graphql/threadMessagesCreatedSubscription'
import JobOffer from '../../components/Job/JobOffer'

export default {
  name: 'inbox2',
  data () {
    return {
      inbox: [],
      messages: [],
      unreadMessages: [],
      inboxLoaded: false,
      messageLoaded: false,
      activeMessage: '',
      messagesFound: false,
      activeHash: '',
      fromUsername: '',
      toUsername: '',
      messageLoadedIndex: false,
      activeLock: '',
      myUsername: localStorage.getItem('username'),
      smallScreenView: true,
      activeInbox: '',
      lastActive: {
        to: null,
        from: null
      },
      newMessages: 0,
      newMessagesHashes: [],
      withinLastSeconds: 1000 * 60 * 5,
      firstLoad: false,
      sortBy: 'default',
      page: 1,
      perPage: 6,
      loadMoreCount: 1,
      pagination: {
        per_page: this.perPage,
        total: 0
      },
      customDetail: {},
      jobDetail: null
    }
  },
  components: {
    JobOffer,
    InboxItem,
    MessageInbox
  },
  async created () {
    await this.processJwt()
  },
  props: ['hash'],
  mounted () {
    this.getInbox(this.page, this.perPage)

    this.$apollo.addSmartSubscription('threadCreated', {
      query: threadsCreatedSubscription,
      variables () {
        return {
          clientHash: this.getUserHash()
        }
      },
      result ({ data }) {
        if (data.onCreateThread) {
          ApiService.getThreadInfo(data.onCreateThread.thread_hash).then(resp => {
            const data = resp.data.data

            let inboxIndex = -1
            let inboxItemName = ''
            for (let i = 0; i < this.inbox.length; i++) {
              if (this.inbox[i].hash === data.hash) {
                this.inbox[i].last_message = data.last_message
                this.inbox[i].last_message_received = data.last_message_received
                this.inbox[i].to_last_active = data.to_last_active
                this.inbox[i].from_last_active = data.from_last_active
                this.inbox[i].unread = data.unread
                inboxItemName = 'inboxItem' + i
                inboxIndex = i
              }
            }

            if (inboxIndex > -1) {
              if (this.activeInbox === 'messageSeen0' && this.activeInbox !== 'messageSeen' + inboxIndex) {
                this.activeInbox = 'messageSeen1'
              } else if (inboxIndex > 0) {
                this.activeInbox = 'messageSeen' + (parseInt(this.activeInbox.replace(/^\D+/g, '')) + 1)
              }
              this.inbox.unshift(this.inbox.splice(inboxIndex, 1)[0])
            } else {
              this.activeInbox = (this.activeInbox === 'messageSeen0') ? 'messageSeen1' : 'messageSeen' + (parseInt(this.activeInbox.replace(/^\D+/g, '')) + 1)
              inboxItemName = 'inboxItem0'
              this.inbox.unshift(data)
            }

            this.$nextTick(() => {
              if (this.$refs[inboxItemName] !== undefined) {
                const profileImage = this.$refs[inboxItemName][0].$el.getElementsByTagName('img')[0]
                if (profileImage !== undefined) {
                  profileImage.setAttribute('src', profileImage.getAttribute('data-src'))
                }
                this.$refs[inboxItemName][0].reload()
              }
            })
          })
        }
      }
    })
  },
  watch: {
    newMessageThreads: function (newVal) {
      if (newVal.length > 0 && this.firstLoad) {
        this.newMessages = newVal.length
        let inboxes = this.findInboxes(this.unreadDiff(newVal))
        for (let i = 0; i < inboxes.length; i++) {
          inboxes[i].unread = parseInt(inboxes[i].unread) + 1
        }
      } else {
        this.newMessages = newVal.length
        this.firstLoad = true
      }
    }
  },
  methods: {
    ...mapActions([
      'setNewMessageCount',
      'processJwt'
    ]),
    changeCustomStatus (hash, status, activeMessage, delivery, revisions, price) {
      let fromUsername = ''
      let toUsername = ''
      for (let i = 0; i < this.inbox.length; i++) {
        if (this.inbox[i].hash === hash) {
          this.inbox[i].custom_status = status
          this.inbox[i].custom_task_delivery = delivery
          this.inbox[i].custom_task_revisions = revisions
          this.inbox[i].custom_task_price = price
          fromUsername = this.inbox[i].from_username
          toUsername = this.inbox[i].to_username
          if (status === 2 || status === 4) {
            this.inbox[i].locked = 1
            this.activeLock = true
          }
          this.getMessage(hash, activeMessage, this.activeLock = true, true, fromUsername, toUsername)
          break
        }
      }
    },
    onScroll ({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (this.pagination.per_page < this.pagination.total && Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
        this.loadMoreCount += 1
        this.showMessages = this.perPage * this.loadMoreCount
        this.getInbox(1, this.showMessages, false)
      }
    },
    getInbox (page, perPage, getMessage = true, buildCustomQuote = false) {
      let displayView = window.getComputedStyle(document.getElementById('message-container'), 'style').display !== 'none'

      ApiService.getInbox(page, perPage, this.sortBy).then(response => {
        this.inbox = response.data.data.results
        this.pagination = response.data.data.pagination
        this.taskTitle = this.inbox.title
        if (this.inbox.length > 0 && displayView && getMessage && !this.hash) {
          this.activeInbox = 'messageSeen0'
          this.getMessage(this.inbox[0].hash, 'messageSeen0', this.inbox[0].locked, true, this.inbox[0].from_username, this.inbox[0].to_username)
          this.lastActive.from = this.inbox[0].from_last_active
          this.lastActive.to = this.inbox[0].to_last_active
        } else if (this.inbox.length > 0 && getMessage && this.hash) {
          const routedMessage = this.inbox.filter(message => message.hash === this.hash)[0]
          const routedMessageIndex = this.inbox.findIndex(message => message[0].hash === this.hash)
          this.getMessage(routedMessage.hash, 'messageSeen' + routedMessageIndex, routedMessage.locked, true, routedMessage.from_username, routedMessage.to_username)
          this.lastActive.from = routedMessage.from_last_active
          this.lastActive.to = routedMessage.to_last_active
        } else {
          this.inboxLoaded = true
          this.messageLoaded = true
        }

        if (buildCustomQuote) {
          this.createCustomQuote(this.findInboxes([this.inbox[0].hash])[0])
          this.activeLock = this.inbox[0].locked
        }
      })
    },
    formatProfilePhoto (img) {
      return helper.formatCloudinaryUrl(img, 'profile')
    },
    createCustomQuote (inbox) {
      this.customDetail = {
        'status': inbox.custom_status,
        'info': inbox.additional_information,
        'delivery': inbox.custom_task_delivery,
        'hash': inbox.custom_task_hash,
        'revisions': inbox.custom_task_revisions,
        'slug': inbox.custom_task_slug,
        'price': inbox.custom_task_price,
        'quote_owner': inbox.from_username,
        'task_owner': inbox.to_username,
        'author_photo': inbox.from_photo
      }
    },
    createJobInfo (inbox) {
      this.jobDetail = {
        'page': 'my_messages',
        'hash': inbox.job_hash,
        'offer_hash': inbox.job_offer_hash,
        'status': inbox.job_status,
        'date_expires': inbox.job_date_expires,
        'price': inbox.job_price,
        'revisions': inbox.job_revisions,
        'description': inbox.job_description,
        'price_from': inbox.job_budget_from,
        'price_to': inbox.job_budget_to,
        'delivery': inbox.job_delivery
      }
    },
    getMessage (hash, id, lock = null, createCustom = true, fromUsername, toUsername, watch = true) {
      this.smallScreenView = false
      this.fromUsername = fromUsername
      this.toUsername = toUsername
      let el = document.getElementById(id)

      if (el !== null) {
        let els = document.getElementsByClassName('message-active')
        while (els.length > 0) {
          els[0].classList.remove('message-active')
        }
        el.classList.add('message-active')
      }

      let inbox = this.findInboxes([hash])[0]
      if (parseInt(inbox.unread) > 0) {
        inbox.unread = 0
        this.setNewMessageCount(this.getNewMessageCount() === 0 ? 0 : this.getNewMessageCount() - 1)
        this.newMessages = this.getNewMessageCount()
      } else {
        this.newMessages = this.getNewMessageCount()
      }
      this.newMessagesHashes = this.getMessageThreads()
      this.messageLoaded = false
      this.username = localStorage.getItem('username')

      if (createCustom) {
        this.createCustomQuote(inbox)
        this.createJobInfo(inbox)
      }

      ApiService.getMessages(hash).then(response => {
        let lastHash = ''

        response.data.data.forEach(function (message) {
          if (message.hash === lastHash) {
            message.repeat = true
          } else {
            message.repeat = false
            lastHash = message.hash
          }
        })

        this.unreadMessages = response.data.data.filter(function (message) {
          return message.read < 1
        })
        this.messages = response.data.data
        this.activeMessage = id
        this.activeHash = hash
        this.activeInbox = id
        if (lock !== null) {
          this.activeLock = lock
        }
        this.inboxLoaded = true
        this.messageLoaded = true
        this.title = this.messages.task_title

        if (this.customDetail.status !== null && this.customDetail.status.toString() !== this.messages[0].custom_status && !createCustom) {
          this.getInbox(1, this.perPage, false, true)
        }

        const lastMessage = this.messages[this.messages.length - 1]
        inbox.last_message = (lastMessage.body !== null ? lastMessage.body : null)
        inbox.last_message_received = lastMessage.date_created
        const inboxItemName = 'inboxItem' + id.replace(/^\D+/g, '')

        this.$nextTick(() => {
          if (this.$refs[inboxItemName] !== undefined) {
            if (typeof this.$refs[inboxItemName][0].reload === 'function') {
              this.$refs[inboxItemName][0].reload()
            }
          }
        })

        if (watch) {
          if (this.$apollo.subscriptions.threadMessagesCreated) {
            this.$apollo.subscriptions.threadMessagesCreated.skip = true
          }
          this.$apollo.addSmartSubscription('threadMessagesCreated', {
            query: threadMessagesCreatedSubscription,
            variables () {
              return {
                clientHash: this.getUserHash(),
                threadHash: hash
              }
            },
            result ({ data }) {
              if (data.onCreateMessage) {
                const payload = data.onCreateMessage
                ApiService.getMessage(payload.thread_hash, payload.message_hash).then((resp) => {
                  this.unreadMessages.push(resp.data.data)
                  this.$nextTick(() => {
                    this.$refs['messageInbox'].scrollToEnd()
                  })
                })
              }
            }
          })
        }
        this.removeUnread(id)
      })
    },
    removeUnread (id) {
      // Remove unread after n milliseconds
      this.$nextTick(function () {
        let el = document.getElementById(id)
        if (el.classList.contains('not-seen')) {
          setTimeout(function () {
            el.classList.remove('not-seen')
          }, 5000)
        }
      })
    },
    date (date) {
      if (date === null) {
        return this.$t('no_payment_date_message')
      }
      const newDate = new Date(date)
      return newDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) + ' ' + newDate.toLocaleDateString()
    },
    displayInbox () {
      this.smallScreenView = true
    },
    unreadDiff (inbox) {
      let oldValue = this.newMessagesHashes
      this.newMessagesHashes = inbox
      return inbox.filter(x => oldValue.indexOf(x) === -1)
    },
    findInboxes (hashes) {
      return this.inbox.filter(function (inbox) {
        return hashes.indexOf(inbox.hash) !== -1
      })
    },
    updateSort (value) {
      this.sortBy = value
      this.page = 1
      this.perPage = 6
      this.inboxLoaded = false
      this.messageLoaded = false
      this.getInbox(this.page, this.perPage)
    },
    handleJobOpenOffer () {
      this.$bvModal.show('job-offer-modal')
    },
    handleJobCancel (jobOffer) {
      this.$swal({
        title: this.$t('job.view.cancel_offer.seller.title'),
        text: this.$t('job.view.cancel_offer.seller.description'),
        type: 'warning',
        showCancelButton: true,
        preConfirm (inputValue) {
          if (inputValue) {
            return ApiService.cancelOffer(jobOffer.hash, jobOffer.offer_hash).then(() => {}).catch((error) => {
              this.$swal.showValidationMessage(
                error.response.data.error_message
              )
            })
          }
        }
      }).then((result) => {
        if (result.value === true) {
          this.$swal({
            title: this.$t('job.view.cancel_offer.seller.success_title'),
            text: this.$t('job.view.cancel_offer.seller.success_description')
          })
          this.jobDetail.status = 4
        }
      })
    }
  },
  computed: {
    ...mapGetters(['getNewMessageCount', 'getMessageThreads', 'getUserHash']),
    ...mapState({ newMessageThreads: state => state.messages.newMessageThreads })
  },
  destroyed () {
    if (this.$apollo.subscriptions.threadMessagesCreated) {
      this.$apollo.subscriptions.threadMessagesCreated.skip = true
    }
    if (this.$apollo.subscriptions.threadCreated) {
      this.$apollo.subscriptions.threadCreated.skip = true
    }
  }
}
</script>

<style lang="scss" scoped>
@mixin sm-plus10-down {
  @media (max-width: #{$breakpoint-sm+10}) {
    @content;
  }
}

$grey-border-and-text: #e1e1e1;
$grey-bg: #f5f5f5;
$grey-dark-border: #c4c2c2;
$grey-dark-text: #7c7c7d;

.load-more {
  border: 1px solid $grey-border-and-text;
  border-radius: 5px;
}

@include sm-plus10-down {
  .message-list .list-group {
    height: auto;
  }
}

::v-deep .new-message-icon {
  background: $brand-sky;
  top: -2px;
  right: -2px;
  padding: 0px 4px;
  border-radius: 20px;
  font-size: 10px;
  color: white;
}

.content-box {
  background: transparent;
  box-shadow: none;
}

.list-group-item {
  border-radius: unset !important;
  border-bottom: 1px solid $grey-border-and-text !important;
  background: transparent;
  cursor: pointer;
}

.message-chat-history .list-group-item:hover {
  box-shadow: none;
}

.message-active, .message-container:hover {
  background-color: $grey-bg;
}

#message-container {
  border: 1px solid #ddd;
  border-radius: 7px;
  height: 79vh;
  max-height: 585px;
  background: white;
}

::v-deep .round {
  border-radius: 50% !important;
}

.message-list-column {
  background: white;
  border-radius: 7px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.0675);
}

::v-deep .message-list {
  font-size: 12px;
  color: $grey-dark-text;

  .message-list-hash {
    color: $grey-dark-text;
  }

  .message-list-user {
    font-size: 1rem;
    color: $brand-denim;
  }
}

::v-deep .message-list-content {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.no-messages {
  color: lightslategray;
}

.show {
  display: none;
}

.message-container {
  border-left: 2px solid transparent !important;
}

.message-container.not-seen {
  border-left: 2px solid $brand-tangerine !important;
}

.message-active {
  border-left: 2px solid #44aae1 !important;
}

.message-container ::v-deep .list-box-content .title {
  font-weight: normal !important;
}

.message-box-container {
  height: 58.5vh !important;
}
</style>
<style lang="scss">

.fade-enter-active, .fade-leave-active {
  transition: opacity .25s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.avatar-fallback {
  background: $grey-dark-text;
  font-size: 1.25rem;
}

.message-list .list-group-item {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: row;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
  flex-direction: row;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  justify-content: space-between;
}

.message-list .list-group:hover {
  box-shadow: inset 1px 0 0 #dfdfdf, inset 1px 0 0 #dfdfdf, 0 0 1px 0 rgba(60, 64, 67, 0.1), 0 1px 3px 1px rgba(60, 64, 67, 0.1);
}

.message-list .list-group-item .form-check {
  padding-left: 0;
  -webkit-align-items: center;
  -moz-box-align: center;
  align-items: center;
  justify-items: center;
  -webkit-align-self: center;
  align-self: center;
}

.message-list .list-group-item .form-check .custom-control {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
}

.message-list .list-group-item .list-box-user {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  width: 12%;
}

.message-list .list-group-item .list-box-user img {
  padding: 2px;
  border-radius: 2px;
}

.message-list .list-group-item .list-box-user .name {
  font-size: 11px;
  font-weight: 500;
  margin-top: 2px;
  text-overflow: ellipsis;
  width: 110%;
  white-space: nowrap;
  overflow: hidden;
}

.message-list .list-group-item .list-box-user .name i {
  font-size: 10px;
  margin-right: 3px;
}

.message-list .list-group-item .list-box-user .name i.online {
  color: #2ECC71;
}

.message-list .list-group-item .list-box-user .name i.offline {
  color: #636c72;
}

.message-list .list-group-item .list-box-content {
  /* width: 75%; */
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
}

.message-list .list-group-item .list-box-content .title {
  font-weight: 700;
  color: #292b2c;
  font-size: .875rem;
  letter-spacing: .2px;
  text-overflow: ellipsis;
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
}

.message-list .list-group-item .list-box-content .message-text {
  color: #292b2c;
  font-size: 12px;
  max-width: 80%;
  font-weight: bold;
}

.message-list .list-group-item .list-box-content .time {
  font-size: 11px;
  color: #636c72;
  margin-top: 5px;
}

.message-list .list-group-item .list-box-action {
  display: -webkit-flex;
  display: -moz-box;
  display: flex;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  flex-direction: column;
  -webkit-justify-content: space-evenly;
  -moz-box-pack: space-evenly;
  justify-content: space-evenly;
}

.message-list .list-group-item .list-box-action a {
  color: #292b2c;
}

.message-list .list-group-item .list-box-action a:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.message-list .list-group-item.seen .list-box-content .title {
  font-weight: normal;
}

.message-list .list-group-item.seen .list-box-content .message-text {
  font-weight: normal;
}

.message-chat-history, .message-chat-history-list {
  display: block;
  overflow-y: auto;
}

.message-chat-history {
  margin-bottom: auto;
  flex: 1;
}

.message-chat-history-list {
  max-height: 508px;

  .pagination {
    .page-item {
      padding: 0;
    }
  }
}

.message-chat {
  max-height: 580px;
}

.list-box-avatar img, .avatar-fallback {
  min-height: 42px;
  min-width: 42px;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
}

.type-message {
  width: 100%;
  display: block;
  clear: both;
  margin-top: 15px;
}

.type-message .type-form {
  width: 100%;
  position: relative;
}

.type-message .type-form .form-control {
  width: 100%;
  border: 0 !important;
  box-shadow: none !important;
}

.type-message .type-form .button-wrap {
  position: absolute;
  right: 0;
  top: 0;
}

.type-message .type-form .button-wrap .btn {
  border: 0 !important;
  box-shadow: none !important;
}

.message-compose .type-form {
  border: none !important;
}

.message-compose textarea {
  height: 300px;
}

.type-form-footer {
  padding-top: 10px;
}

.type-form-footer .btn {
  margin-right: 5px;
}
</style>
