import gql from 'graphql-tag'

const threadsCreatedSubscription = gql`
    subscription threads($clientHash: String) {
      onCreateThread(client_hash: $clientHash) {
        client_hash,
        thread_hash
      }
    }
`

export default threadsCreatedSubscription
